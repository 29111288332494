import { graphql } from 'gatsby';
import orderBy from 'lodash/orderBy';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import RichContentWithComponents from '@connected/RichContentWithComponents';
import Sections from '@connected/Sections';
import SEO from '@connected/SEO';
//import SocialShare from '@presentation/SocialShare';
import CardList from '@layouts/CardList';
import ProfileBanner from '@presentation/ProfileBanner';
//import ProfileInfo from '@presentation/ProfileInfo';
const ProfilePage = ({ data }) => {
    const profileData = data.kontentItemProfile;
    const linkedInUrl = profileData?.elements?.linkedin_url?.value;
    if (!profileData) {
        return null;
    }
    return (<>
      <SEO {...profileData.metadata} image={profileData.elements?.og_image?.value?.[0]?.url} url={profileData.url}/>
      {renderDetail()}
      {renderArticles()}
    </>);
    function renderDetail() {
        return (<>
        <ProfileBanner avatar={profileData?.elements?.avatar?.value?.[0]?.fluid} name={profileData?.elements?.standard_content__title?.value || ''} title={profileData.elements?.title?.value} summary={profileData.elements?.standard_content__summary?.value || ''} linkedInUrl={linkedInUrl} backgroundImage={profileData.elements?.backgroundImage?.value?.[0]?.fluid}/>
        

        <div className="profile-content">
          <RichContentWithComponents content={profileData.elements?.content?.resolvedData?.html || ''} images={profileData.elements?.content?.images || []} linkedItems={profileData.elements?.content?.linked_items || []} links={profileData.elements?.content?.links || []}/>
        </div>
        <Sections {...profileData.elements?.sections__sections}/>
      </>);
    }
    function renderArticles() {
        // Cannot filter at the schema level so this is all content items, will filter here instead.
        // @note: Might be a performance optimisation to have a custom resolver for this down the line - depends on amount of content!
        let relatedArticleData = data.kontentItemProfile.usedByContentItems?.filter(item => item?.system?.type === 'article');
        if (!relatedArticleData?.length) {
            return null;
        }
        // Get the most recent 3 articles.
        relatedArticleData = orderBy(relatedArticleData, ['elements.start_date.value'], ['desc']);
        return (<>
        <CardList title="Read my thoughts">
          {relatedArticleData.map(article => (<LinkedItem key={article.system?.id || ''} item={article}/>))}
        </CardList>
        
      </>);
    }
};
export const query = graphql `
  query ProfilePageQuery($slug: String!) {
    kontentItemProfile(elements: { slug: { value: { eq: $slug } } }) {
      ...SEOFragment
      url
      elements {
        og_image: metadata__page_image {
          value {
            url
          }
        }
        avatar {
          value {
            fluid(maxWidth: 400) {
              ...KontentAssetFluid_withWebp
            }
          }
        }
        backgroundImage: background_image {
          value {
            fluid(maxWidth: 1920) {
              ...KontentAssetFluid_withWebp
            }
          }
        }
        content {
          ...KontentRichTextElementFragment
        }
        sections__sections {
          linked_items {
            ...LinkedItemsFragment
          }
        }
        standard_content__title {
          value
        }
        standard_content__summary {
          value
        }
        title {
          value
        }
        linkedin_url {
          value
        }
        email_address {
          value
        }
      }
      usedByContentItems {
        ...LinkedItemsFragment
      }
    }
  }
`;
export default ProfilePage;
