import classNames from 'classnames';
import { motion } from 'framer-motion';
// import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
// import useFade from '@hooks/animation/useFade';
import useFadeUp from '@hooks/animation/useFadeUp';
import formatTitle from '@utils/formatTitle';
const ProfileBanner = ({ avatar, name, title, summary, linkedInUrl, }) => {
    const containerRef = useRef(null);
    // const bgImageProps = useFade({ ref: containerRef, delay: 0.1 });
    const nameProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const avatarProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const linkedinProps = useFadeUp({ ref: containerRef, delay: 0.4 });
    const summaryProps = useFadeUp({ ref: containerRef, delay: 0.5 });
    /* const data: IProfileBannerQuery = useStaticQuery(graphql`
      {
        file(name: { eq: "bg-default" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `);*/
    /*const bgImage = backgroundImage
      ? backgroundImage
      : data.file.childImageSharp.fluid;*/
    const bannerClassName = classNames({
        'template-banner': true,
        author: true,
        'template-banner--thin': true,
        greyscale: true,
    });
    return (<section ref={containerRef} className={bannerClassName}>
      

      <div className="site-wide grid-container grid-container--full">
        <div className="grid-column">
          <div className="profile-banner">
            <div className="profile-banner__content-wrapper">
              <div className="profile-banner__content">
                {name && (<motion.h1 className="profile-banner__title h1-styling" {...nameProps}>
                    {formatTitle(name)}
                  </motion.h1>)}

                {title && (<motion.div className="profile-banner__role" {...titleProps}>
                    {title}
                  </motion.div>)}

                {linkedInUrl && (<motion.div className="profile-info__item" {...linkedinProps}>
                    <a href={linkedInUrl} target="_blank" rel="noopener noreferrer" className="profile-info__link profile-info__link--standalone">
                      Connect with me on LinkedIn
                    </a>
                  </motion.div>)}

                {summary && (<motion.div className="profile-banner__summary" {...summaryProps}>
                    {summary}
                  </motion.div>)}
              </div>
              <motion.div className="profile-banner__image" {...avatarProps}>
                <Image fluid={avatar}/>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>);
};
export default ProfileBanner;
